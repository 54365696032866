import { runtimeConfig } from "@src/runtime-config";
import { getDashboard20ApiUrl } from "@utils/url-utils";
import axios from "axios";
import { useCallback, useMemo } from "react";

interface SuccessResponse {
  // eslint-disable-next-line @typescript-eslint/naming-convention -- API response
  success: boolean;
}

interface PostInitOriginalPayload {
  // Application related
  /** Optional url of the client who calls the API. Used only when calling the API through a proxy */
  originalUrl: string;
  /** Version of the application e.g. 1.0.0 */
  version: string;
  /** Name of the application e.g. "SXG Dashboard" */
  application: string;

  // User related
  /** Id of the logged in user */
  userId?: string;
  /** Email of the logged in user */
  userEmail?: string;
  /** The time the app was initialized in milliseconds from epoch */
  appInitTime?: string;
  /** Whether the user has given consent to tracking */
  isConsentGiven?: boolean;
  /** The language that was used to load the page */
  language?: string;

  // Workspace related
  /** Number of workspaces the user has access to */
  workspaces?: number;
  /** The workspace role for the logged in user */
  workspaceRole?: string;
  /** Tags of the workspace */
  workspaceTags?: string[];

  // URL related
  /** The URL pathname of the current page */
  urlPathname?: string;
  /** The URL hash of the current page */
  urlHash?: string[];
  /** The URL search of the current page */
  urlQuery?: string[];

  // Device related
  /** Defines the width of the screen (it doesn't matter if the browser is using less space) */
  screenWidth?: number;
  /** Defines the actual width of the window */
  windowWidth?: number;
  /** Defines the height of the screen (it doesn't matter if the browser is using less space) */
  screenHeight?: number;
  /** Defines the actual height of the window */
  windowHeight?: number;
  /** Defines the orientation of the window (landscape or portrait) */
  windowOrientation?: OrientationType;

  // ULM related
  /** Specifies the client used to load the ULM, e.g. Stream */
  ulmClient?: string;
  /** Specified the version used to load the ULM e.g. 8.0.0 for Scene */
  ulmClientVersion?: string;
}

type PostInitPayload = Required<
  Pick<
    PostInitOriginalPayload,
    | "version"
    | "application"
    | "userId"
    | "userEmail"
    | "isConsentGiven"
    | "language"
    | "workspaces"
    | "workspaceRole"
    | "workspaceTags"
    | "urlPathname"
    | "urlQuery"
    | "screenWidth"
    | "windowWidth"
    | "screenHeight"
    | "windowHeight"
    | "windowOrientation"
  >
> &
  Pick<PostInitOriginalPayload, "appInitTime">;

interface PostFeedbackPayload {
  /** Name of the application e.g. "SXG Dashboard" */
  application: string;

  /** Version of the application e.g. 1.0.0 */
  appVersion: string;

  /** The message that was given by the user */
  feedbackMessage?: string;

  /** The motive that the user was giving feedback, e.g. general or annotations */
  featureName: string;

  /** The rating that the user gave to the application in a range 1-5 */
  selectedRating?: number;

  /** The id of the company where the user is giving feedback from */
  companyId?: string;

  /** The id of the user that is giving feedback */
  userId?: string;

  /** The name of the company where the user is giving feedback from */
  companyName?: string;

  /** The email of the user that is giving feedback */
  userEmail?: string;

  /** The role of the user that is giving feedback */
  workspaceRole?: string;
}

interface SphereXGApi {
  /** Makes a POST to the init route */
  postInit(payload: PostInitPayload): Promise<SuccessResponse>;

  /** Makes a POST to the feedback route */
  postFeedback(payload: PostFeedbackPayload): Promise<SuccessResponse>;
}

/**
 * Hook that provides access to the Sphere XG API.
 */
export function useSphereXGApi(): SphereXGApi {
  /** Axios instance to simplify calls to Sphere XG API */
  const axiosInstance = useMemo(() => {
    /** Url for accessing the Sphere XG API including numbered dev environment if applies */
    const dashboard20Api = getDashboard20ApiUrl(
      runtimeConfig.urls.dashboard20Api,
      runtimeConfig.numberedEnv
    );
    return axios.create({ baseURL: dashboard20Api });
  }, []);

  /** Wrapper function to make a post to the API and return the body */
  const post = useCallback(
    async (url: string, payload: object): Promise<SuccessResponse> => {
      const isLocal =
        process.env.NODE_ENV === "development" ||
        process.env.NODE_ENV === "test";
      if (isLocal) {
        // eslint-disable-next-line no-console -- Logging API calls in local environment
        console.log(
          `Using localhost, skipping POST ${axiosInstance.getUri({ url })}`,
          payload
        );
        // eslint-disable-next-line @typescript-eslint/naming-convention -- API response
        return { success: true };
      }
      const request = await axiosInstance.post(url, payload);
      return request.data;
    },
    [axiosInstance]
  );

  /** Makes a POST to the init route */
  const postInit = useCallback(
    async (payload: PostInitPayload): Promise<SuccessResponse> => {
      return post("/init", payload);
    },
    [post]
  );

  /** Makes a POST to the feedback route */
  const postFeedback = useCallback(
    (payload: PostFeedbackPayload): Promise<SuccessResponse> => {
      return post("/feedback", payload);
    },
    [post]
  );

  return { postInit, postFeedback };
}
